<template>
  <div name="index">
    <div class="container" style="margin: 0 10px">
      <!-- <div class="source">
        当前位置：&nbsp;&nbsp;<router-link :to="{ path: '/' }"
          >首页
        </router-link>
        &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;
        {{ Number(id) === 5 ? "支出" : "收入" }}
      </div> -->
      <div class="tab3-top-2-new">
        <a href="javascript:void(0);"
          @click="changeTab3Tab(1, '收入', true)"
          :class="tab3_active === 1 ? 'active' : ''"
        >
          {{ $t('index.capitalItem.inCome') }}
        </a>
        <a href="javascript:void(0);"
          @click="changeTab3Tab(2, '支出', true)"
          :class="tab3_active === 2 ? 'active' : ''"
        >
          {{ $t('index.capitalItem.expend') }}
        </a>
      </div>
      <div class="news">
        <div class="news-bg">
          {{ Number(id) === 5 ? $t('index.capitalItem.expend') : $t('index.capitalItem.inCome') }}
          {{ $t('public.queryBtn') }}
        </div>
        <div class="list">
          <input type="text" class="sfz"
            :placeholder="$t('public.keywordQuery')"
            v-model="searchText"
          />
          <button
            class="button col button-fill button-large"
            v-on:click="searchClick"
          >
            {{ $t('public.queryBtn') }}
          </button>
        </div>
        <FlatList
          :list="org3List"
          height="460px"
          :showLoadSpin="showLoadSpin"
          :isRefresh="isRefresh"
        >
          <div
            v-for="(item, index) in org3List"
            :key="item.id"
            class="list-box"
          >
            <!-- <div>
              <span class="title">{{ item.abstracts }}</span>
            </div> -->
            <div style="display: flex">
              <div class="title-box" style="flex: 1">
                <span class="title">{{ item.abstracts }}</span>
              </div>
              <div class="icon-box">
                <img
                  style="width: 20px; margin: 0 5px"
                  class="icon"
                  src="../assets/icon/play.png"
                  v-show="!item.playStatus"
                  @click="playControl(index)"
                />
                <img
                  style="width: 20px; margin: 0 5px"
                  class="icon"
                  src="../assets/icon/play_selected.png"
                  v-show="item.playStatus"
                  @click="playControl(index)"
                />
                <!-- <img
                  style="width: 20px; margin: 0 5px" class="icon" src="../assets/icon/policy.png"
                  @click="seePolicy(index)"
                /> -->
              </div>
            </div>
            <div>
              <span>{{ $t('index.capitalItem.category') }}：</span
              ><strong>{{ item.subjects }}（{{ item.type }}）</strong>
            </div>
            <div>
              <span>{{ $t('index.capitalItem.amount') }}：</span>
              <span class="money">{{ item.money }} {{ $t('public.element') }}</span>
              <span class="tousu_btn" @click="show(item.id)"> {{ $t('public.fastComplaint') }} </span>
            </div>
            <div>
              <span>{{ $t('index.capitalItem.time') }}：</span><strong>{{ item.time }}</strong>
            </div>
            <div><span>{{ $t('index.capitalItem.supply') }}：</span>{{ item.content || "-" }}</div>
            <div class="imglist" v-if="item.photo">
              <!-- 123 -->
              <img :src="`${src}?resize=100x100`"
                v-for="(src, index) in item.photo.split(',')"
                @click="previewImg(item, 'photo')"
                class="org_list_img"
                :key="index"
              />
            </div>
          </div>
        </FlatList>

        <!-- 音频播放 -->
        <audio :src="audioSrc" autoplay style="display: none"></audio>
        <!-- 查看政策 -->
        <van-popup
          v-model="showPopup"
          :round="true"
          :lock-scroll="true"
          style="width: 80%; max-height: 80%; text-align: left"
        >
          <van-cell :title="$t('index.subsidy.grantObj')" :label="popupData.grantObj" />
          <van-cell :title="$t('index.subsidy.auditProcess')" :label="popupData.auditProcess" />
          <van-cell :title="$t('index.subsidy.grantStandard')" :label="popupData.grantStandard" />
          <van-cell :title="$t('index.subsidy.grantTime')" :label="popupData.grantTime" />
          <van-cell :title="$t('index.subsidy.consultUnit')" :label="popupData.consultingUnit" />
          <van-cell :title="$t('index.subsidy.consultPhone')" :label="popupData.consultingPhone" />
          <van-cell :title="$t('index.subsidy.policyStatute')" :label="popupData.policy" />
        </van-popup>
      </div>
      <div class="footer">
        <p>{{ $t('footer.producer') }}：{{ $t('footer.producerName') }}</p>
        <p>{{ $t('footer.contactAddress') }}：{{ $t('footer.contactAddressName') }}</p>
      </div>
    </div>
    <tousu_modal :moneyId="moneyId" :remark="remark" :addsan="addsan" />
  </div>
</template>

<script>
import BASE from "../utils/base";
import FlatList from "../components/FlatList";
import { calcScroll, listenScrollToBottom } from "../utils/util";
import { ImagePreview } from "vant";
import tousu_modal from "../components/tousu_modal";

export default {
  name: "IncomeList",
  components: { FlatList, tousu_modal },
  watch: {},
  data() {
    return {
      // id: this.$route.params.id,
      // code: this.$route.params.code,
      id: 1,
      code: "652302102200", //组织代码
      org3List: [],
      showLoadSpin: 1,
      isRefresh: false,
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      searchText: "",
      moneyId: "",
      remark: "",
      addsan: true,

      // 播放状态控制
      playStatus: true,
      // 播放内容
      audioSrc: "",
      // 查看政策弹出框
      showPopup: false,
      // 弹出框数据
      popupData: {},
      tab3_active: 1,
    };
  },
  methods: {
    async searchClick() {
      this.pageNo = 1;
      const res = await this.getList({
        abstracts: "*" + this.searchText + "*",
      });
      this.pageNo = res.data.result.current;
      this.pages = res.data.result.pages;
      this.pageSize = res.data.result.size;
      this.org3List = res.data.result.records;
      this.setFooter(this.org3List.length === this.pageSize);
    },
    getList(param = {}) {
      let params = {
        1: {
          url: "zxSuperviseSMoney/list",
          options: { inorout: "收入" },
        },
        2: {
          url: "zxSuperviseSMoney/list",
          options: { inorout: "支出" },
        },
      };
      console.log(this.id);
      return this.axios.get(
        BASE.genUrl(
          params[this.id].url,
          Object.assign(params[this.id].options, {
            sysOrgCode: this.code,
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            ...param,
          })
        )
      );
    },
    onLoad() {
      const that = this;
      listenScrollToBottom({
        ele: ".list-wrap",
        callBack: (ele) => {
          that.showLoadSpin = 1;
          return calcScroll({
            ele,
            callBack: async () => {
              that.pageNo += 1;
              if (that.pageNo > that.pages) {
                that.showLoadSpin = 2;
                return;
              }
              const res = await that.getList({
                abstracts: "*" + this.searchText + "*",
              });
              let data = res.data;
              if (data.result) {
                that.org3List = that.org3List.concat(data.result.records);
              }
            },
          });
        },
      });
    },
    // async onRefresh() {
    //   this.isRefresh = true;
    //   this.pageNo = 1;
    //   const res = await this.getList({
    //     abstracts: "*" + this.searchText + "*",
    //   });
    //   this.isRefresh = false;
    //   this.pageNo = res.data.result.current;
    //   this.pages = res.data.result.pages;
    //   this.pageSize = res.data.result.size;
    //   this.org3List = res.data.result.records;
    //   this.setFooter(this.org3List.length === this.pageSize);
    // },
    trim(str, all = false) {
      return all ? str.replace(/\s+/g, "") : str.replace(/(^\s*)|(\s*$)/g, "");
    },
    previewImg(record, key) {
      const images = record[key].split(",").map((item) => this.trim(item));
      ImagePreview({ images, closeable: true });
    },
    setFooter(bool) {
      if (bool) {
        this.onLoad();
        this.showLoadSpin = 1;
      } else {
        this.showLoadSpin = 0;
      }
    },
    show(id) {
      this.moneyId = id;
      this.$modal.show("modal");
    },
    hide() {
      this.$modal.hide("modal");
    },
    /**
     * 音频播放
     */
    playControl(index) {
      let urlArr = "zxSuperviseSMoney/getvoice"; // 资金

      this.audioSrc = "";

      const that = this;
      const info = this.org3List[index];
      this.axios
        .post(BASE.genUrl(urlArr, { id: info.id }))
        .then(function (res) {
          const arr = that.org3List.filter((e, i) => {
            if (i == index) {
              e.playStatus = !e.playStatus;
              if (e.playStatus) that.audioSrc = res.data.result;
            } else {
              e.playStatus = false;
            }
            return e;
          });
          that.org3List = arr;
        });
    },
    /**
     * 政策查看
     */
    seePolicy(index) {
      console.log(index);
      this.showPopup = !this.showPopup;
    },
    changeTab3Tab(nav, content = "", play = false) {
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = content;

      let remarkList = {
        1: "收",
        2: "支",
      };
      if (this.tab3_active != nav) {
        this.pageNo = 1;
        this.pageSize = 10;
      }
      this.remark = remarkList[nav];
      this.tab3_active = nav;
      this.$previewRefresh();
      this.id = nav;
      this.getList();
    },
  },
  async activated() {
    this.pageNo = 1;
    //this.id = this.$route.query.id
    //this.code = this.$route.query.code
    const res = await this.getList();
    this.pageNo = res.data.result.current;
    this.pages = res.data.result.pages;
    this.pageSize = res.data.result.size;
    this.org3List = res.data.result.records;
    this.setFooter(this.org3List.length === this.pageSize);
  },
};
</script>

<style scoped>
.list-box .tousu_btn {
  display: block;
  float: right;
  border: 1px solid #b51414;
  padding: 0.1rem;
  color: #b51414;
}
.list-box .money {
  color: red;
  font-weight: bold;
}
.list-box span {
  color: #ccc;
}
.list-box .title {
  font-size: 14px;
  color: black;
  font-weight: bold;
}

a.disabled {
  color: #ccc;
}

.data-table-pagination {
  font-size: 14px;
  padding: 1rem;
}

.data-table-pagination a {
  padding: 0 1rem;
}

.detail h3 {
  color: #fff;
  font-size: 16px;
}

.detail h4 {
  color: #fff;
  font-size: 14px;
}

.source {
  text-align: left;
  color: #fff;
  padding: 0.1rem;
  font-size: 14px;
  margin-bottom: 0.3rem;
}

.source a {
  color: #fff;
}

.detail img {
  width: 100%;
}

.zclist li {
  text-align: left;
}

.zclist li a {
  color: #fff;
  font-size: 14px;
}

.item-content {
  padding: 0.5rem 0;
}

.item-inner {
  display: flex;
  flex-direction: row;
}

.item-title {
  padding-right: 0.1rem;
  font-size: 14px;
  width: 85%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.item-after {
  width: 15%;
}

.tab {
  display: none;
}

.tab-active {
  display: block;
}

#tab3 .tab3-top,
#tab2 .tab2-top {
  display: flex;
  flex-direction: row;
}

#tab3 .tab3-middle,
#tab2 .tab2-middle {
  display: flex;
  flex-direction: row;
}

#tab3 .item,
#tab2 .item {
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center; /* 水平居中*/
  align-items: center; /* 垂直居中*/
  margin-right: 0.1rem;
}

#tab2 .item .l {
  width: 50%;
  display: block;
}

#tab2 .item .r {
  border-radius: 0.5rem;
  font-size: 12px;
  background: #fff;
  display: block;
}

#tab2 .item .r h3 {
  margin: 0;
  padding: 0;
}

#tab2 .item .r div {
  padding: 0 0.2rem;
}

#tab2 .info-item .t {
  font-weight: bold;
  font-size: 14px;
}

#tab2 .info-div,
#tab3 .info-div {
  width: 50%;
  margin-right: 0.1rem;
  margin-top: 20px;
}

#tab2 .info-title,
#tab2 .info-item,
#tab3 .info-title,
#tab3 .info-item {
  text-align: center;
}

#tab2 .info-items,
#tab3 .info-items {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 0.3rem 0;
  font-size: 12px;
  border: 1px solid #999;
  border-top: 0;
  color: #999;
}

#tab2 .info-title,
#tab3 .info-title {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.3rem 0;
  font-size: 12px;
  color: #fff;
  background: #b51414;
}

#tab2 .item,
#tab3 .item {
  border-radius: 0.5rem;
  color: #fe9b4a;
  background: #b51414;
  text-align: center;
  padding: 0.5rem 0;
}

.footer {
  color: #fe9b4a;
  text-align: center;
  font-size: 12px;
}

.list {
  margin-top: 1rem;
  padding: 1rem 0.8rem;
  font-size: 14px;
}

.list ul:before {
  background: none;
}

.list .zhengce-select {
  display: flex;
  flex-direction: row;
}

.list .zhengce-select-div {
  width: 100%;
}

.list .zhengce-select-div select {
  text-align: center;
  width: 100%;
  font-size: 16px;
  padding: 0.5rem 0;
  margin-top: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #b51414;
}

.news-bg {
  background: url("../assets/tab-bg.png") center top no-repeat;
  background-size: 60%;
  position: absolute;
  width: 100%;
  top: -5px;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}

.news {
  background: #fff;
  padding-top: 0.5rem;
  position: relative;
  border-radius: 0.5rem;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.payinfo {
  margin-top: 0.5rem;
  display: flex;
  border-radius: 0.5rem;
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
}

.payinfo .payinfo-item {
  flex-grow: 1;
  display: flex;
  /* padding: .5rem; */
}

.payinfo .payinfo-item .payinfo-item-l {
  /* flex-grow: 1; */
  display: flex;
  width: 30%;
  justify-content: center; /* 水平居中*/
  align-items: center; /* 垂直居中*/
}

.payinfo .payinfo-item .payinfo-item-l img {
  width: 50%;
}

.payinfo .payinfo-item .payinfo-item-r {
  color: #fff;
  padding: 1rem 0;
  text-align: left;
  font-size: 14px;
  /* width: 66%;
  float: left; */
}

.payinfo .payinfo-item dd,
.payinfo .payinfo-item dt {
  text-align: left;
}

.container .nav-tabs {
  display: flex;
}

.container .nav-tabs a.tab-link-active {
  color: #b51414;
  background: #fff;
  /* background: url('../../static/choose-bg.png') 100% 100% no-repeat; */
}

.container .nav-tabs .tab1-link,
.container .nav-tabs .tab2-link {
  margin-right: 2px;
}

.container .nav-tabs a {
  /* width: 33%; */
  font-size: 14px;
  color: #fff;
  flex-grow: 1;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  height: 35px;
  line-height: 35px;
  font-weight: bold;
  /* background: #b51414; */
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
}

.container .news .list {
  text-align: left;
  padding: 0.8rem;
}

.container .tabs #tab1 input.sfz,
.container .news input.sfz {
  border: 1px solid #b51414;
  font-size: 1rem;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0.5rem 0;
  text-align: center;
}

.container .news button,
.container #tab1 button {
  background: #b51414;
  border: 0;
  color: #fff;
  font-size: 1rem;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0.5rem 0;
  text-align: center;
}

.container #tab1 .title {
  display: block;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
}

.container .tabs .tab-active {
  background: #fff;
  padding: 0.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
}

.banner {
  width: 100%;
  height: auto;
}

.page {
  background: #b51414;
}

.search {
  margin-top: 10px;
  padding: 10px;
  background: #f7f7f7;
  border-radius: 0.5rem 0.5rem;
  text-align: center;
}

.search span {
  font-size: 14px;
  display: block;
  text-align: center;
  color: #999;
  margin-bottom: 20px;
}

.list-box {
  border: 1px solid #ccc;
  font-size: 12px;
  text-align: left;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.2rem;
}

.imglist {
  overflow-x: hidden;
  white-space: nowrap;
}

.org_list_img {
  width: 33.3333%;
  display: inline-block;
}

.tab3-top-2-new a {
  width: 48%;
  display: inline-block;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
  margin-right: 0.1rem;
  font-size: 14px;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
  color: #fff;
}

.tab3-top-2-new a.active {
  background: #fff;
  color: #b51414;
  border: 1px solid #ccc;
  border-bottom: 0;
}
</style>
